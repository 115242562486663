import styles from './DimensionNode.module.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { Edit } from 'iconsax-react';

interface IDimensionesData {
  id_dimension: string;
  nombre: string;
}
interface Props {
  data: IDimensionesData | undefined;
  onUpdatedId: (id: string) => void;
}

export const DimensionNode = ({ data, onUpdatedId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputNombre, setInputNombre] = useState('');
  const handleOnEdit = () => {
    setIsEditing(true);
  };

  const handleOnSave = () => {
    onUpdatedId('0');
    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'actualizarDimension',
      idDimension: data?.id_dimension,
      nombre: inputNombre
    });
    const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then(function (response) {
        onUpdatedId(data!.id_dimension);
        setIsEditing(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setInputNombre(data!.nombre);
  }, [data]);

  return (
    <>
      {isEditing ? (
        <div className={styles.content}>
          {/* <div style={{ minWidth: '100px', textAlign: 'center' }}> */}
          <div className={styles.id}>{data?.id_dimension}</div>
          <div className={styles.inputContainer}>
            <input className={styles.editInput} value={inputNombre} onChange={(e) => setInputNombre(e.target.value)} />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={classNames(styles.button, { [styles.saveButton]: isEditing })}
              onClick={isEditing ? handleOnSave : handleOnEdit}
            >
              Guardar
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.content}>
            <div className={styles.id}>{data?.id_dimension}</div>
            <div className={styles.label}>{data?.nombre}</div>

            {/* {isEditing ? (
            <div className={styles.inputContainer}>
              <input className={styles.input} value={inputValor} onChange={(e) => setInputValor(e.target.value)} />
            </div>
          ) : (
            <div className={styles.valor}>{data?.valor}</div>
          )} */}

            <div className={styles.buttonContainer}>
              <Edit className={styles.icon} id="Editar" onClick={isEditing ? handleOnSave : handleOnEdit} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
