import React from 'react';
import { Input, Modal, Select } from '@mantine/core';
import { useState } from 'react';
import axios from 'axios';
import { IAgregarUsuarioModal } from './AgregarUsuarioModal.interface';
import styles from './AgregarUsuarioModal.module.scss';
import classNames from 'classnames';

const AgregarUsuarioModal = ({ showModal, onCloseModal }: IAgregarUsuarioModal) => {
  const [hasError, setHasError] = useState(false);

  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [perfil, setPerfil] = useState('2');
  const perfiles = [
    { value: '1', label: 'Administrador' },
    { value: '2', label: 'Usuario Barómetro' }
  ];
  let qs = require('qs');
  let data = qs.stringify({
    f: 'crearUsuario',
    nombre: nombre,
    correo: correo,
    nombreUsuario: nombreUsuario,
    contrasenia: contrasena,
    perfil: perfil
  });

  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  const handleOnAdd = async () => {
    await axios
      .post(ENDPOINT_SUBMIT || '', data, {
        // headers: headers
      })
      .then((response: any) => {
        window.location.reload();
      })
      .catch((error: any) => {
        console.log(error);
      });
    onCloseModal();
  };

  const handleOnCancel = () => {
    setContrasena('');
    setCorreo('');
    setNombre('');
    onCloseModal();
    setNombreUsuario('');
  };
  return (
    <Modal
      centered
      size={496}
      opened={showModal}
      onClose={onCloseModal}
      title="Agregar Usuario"
      styles={{
        modal: { borderRadius: 16 },
        title: {
          paddingLeft: '30px',
          width: '100%',
          textAlign: 'center',
          fontFamily: 'Quicksand',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          marginTop: '20px',
          marginBottom: '13px'
        }
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.username}>Ingrese el nombre:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Usuario"
              type="text"
              radius={12}
              value={nombre}
              onChange={(e) => {
                setHasError(false);
                setNombre(e.target.value);
              }}
              className={styles.input}
            />
          </div>
          <div className={styles.username}>Ingrese el correo electrónico:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Correo electrónico"
              type="text"
              radius={12}
              value={correo}
              onChange={(e) => {
                setHasError(false);
                setCorreo(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>
          <div className={styles.username}>Ingrese el nombre de usuario</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Nombre de usuario"
              type="text"
              radius={12}
              value={nombreUsuario}
              onChange={(e) => {
                setHasError(false);
                setNombreUsuario(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>
          <div className={styles.username}>Ingrese la Contraseña:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Contraseña"
              type="text"
              radius={12}
              value={contrasena}
              onChange={(e) => {
                setHasError(false);
                setContrasena(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>{' '}
          <div className={styles.username}>Seleccione un perfil</div>
          <div className={styles.inputContainer}>
            <Select
              data={perfiles}
              placeholder="Perfil"
              type="text"
              radius={12}
              value={perfil}
              onChange={(value) => {
                setHasError(false);
                setPerfil(value ? value : '1');
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
              defaultValue="2"
            />
          </div>
        </div>
        {hasError && <div style={{ color: 'red' }}> Error en los datos</div>}
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={handleOnCancel}>
            Cancelar
          </button>
          <button
            className={classNames(styles.loginButton, { [styles.disabled]: !nombre || !correo || !contrasena || !perfil })}
            onClick={handleOnAdd}
            disabled={!nombre || !correo || !contrasena || !perfil}
          >
            Crear usuario
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AgregarUsuarioModal;
