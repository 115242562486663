import { Select } from '@mantine/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Indicators } from '../../../Indicators/Indicators';
import styles from './CargaDatos.module.scss';

export const CargaDatos = () => {
  const [selectedProvince, setSelectedProvince] = useState<string | null>(null);
  const [selectedDimension, setSelectedDimension] = useState<string | null>(null);
  const [indicatorsData, setIndicatorsData] = useState<[]>();
  const [updatedId, setUpdatedId] = useState('');

  let qs = require('qs');
  let dataquery = qs.stringify({
    f: 'buscarBarometro',
    idProvincia: selectedProvince,
    idDimension: selectedDimension
  });
  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (indicatorsData) retrieveIndicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);

  const retrieveIndicators = async () => {
    await axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then((response: any) => {
        setIndicatorsData(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const dimensions = [
    { value: '3', label: 'Ser humano' },
    { value: '2', label: 'Estado' },
    { value: '1', label: 'Naturaleza' }
  ];
  const provinces = [
    { value: '1', label: 'Azuay' },
    { value: '2', label: 'Bolívar' },
    { value: '3', label: 'Cañar' },
    { value: '4', label: 'Carchi' },
    { value: '5', label: 'Chimborazo' },
    { value: '6', label: 'Cotopaxi' },
    { value: '7', label: 'El Oro' },
    { value: '8', label: 'Esmeraldas' },
    { value: '9', label: 'Galápagos' },
    { value: '10', label: 'Guayas' },
    { value: '11', label: 'Imbabura' },
    { value: '12', label: 'Loja' },
    { value: '13', label: 'Los Ríos' },
    { value: '14', label: 'Manabí' },
    { value: '15', label: 'Morona Santiago' },
    { value: '16', label: 'Napo' },
    { value: '17', label: 'Orellana' },
    { value: '18', label: 'Pastaza' },
    { value: '19', label: 'Pichincha' },
    { value: '20', label: 'Santa Elena' },
    { value: '21', label: 'Santo Domingo de los Tsáchilas	' },
    { value: '22', label: 'Sucumbíos' },
    { value: '23', label: 'Tungurahua' },
    { value: '24', label: 'Zamora Chinchipe' },
    { value: '25', label: 'Nacional' }
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>Carga Datos - Barómetro de seguridad</div>
      <div className={styles.content}>
        <div className={styles.options}>
          <p>Provincias</p>
          <Select
            radius={12}
            placeholder="Seleccione una provincia"
            data={provinces}
            value={selectedProvince}
            onChange={setSelectedProvince}
          />
          <div className={styles.options}></div>
          <p>Dimensiones</p>
          <Select
            radius={12}
            placeholder="Seleccione una dimensión"
            data={dimensions}
            value={selectedDimension}
            onChange={setSelectedDimension}
          />
          <button className={styles.button} disabled={!selectedDimension || !selectedProvince} onClick={retrieveIndicators}>
            {' '}
            Mostrar Datos{' '}
          </button>
        </div>
        <div className={styles.indicadores}>
          {/* {!!selectedDimension && !!selectedProvince && ( */}
          {/* {indicatorsData && (
            <p>
              Provincia: <b>{provinces.find((item) => item.value === selectedProvince)?.label}</b> <br /> Dimensión{' '}
              <b> {dimensions.find((item) => item.value === selectedDimension)?.label}</b>
            </p>
          )}
          <br />{' '} */}
          <div>
            <Indicators
              data={indicatorsData}
              selectedDimension={selectedDimension}
              selectedProvince={selectedProvince}
              onUpdatedId={setUpdatedId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
