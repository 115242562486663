import React from 'react';
import { Usuarios } from '../../../Usuarios/Usuarios';
import styles from './AdminUsuarios.module.scss';

export const AdminUsuarios = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Administración de Usuarios - Barómetro de Seguridad Integral</div>
      <div className={styles.content}>
        <div className={styles.indicadores}>
          <div className={styles.listContainer}>
            <Usuarios />
          </div>
        </div>
      </div>
    </div>
  );
};
