import React from 'react';
import styles from './Mision.module.scss';
import mision from '../../../../assets/mision.png';

export const Mision = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>Misión</div>
      <div className={styles.content}>
        <div className={styles.introduction}>
          Analizar datos de gobernabilidad para, mediante sistemas informáticos, comprender estructuralmente los fenómenos sociales y de
          seguridad integral en el país, de manera didáctica y útil.
        </div>
        <img src={mision} alt="mision" className={styles.image} />
      </div>
    </div>
  );
};
