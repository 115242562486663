import React from 'react';
import { Input, Modal, Select } from '@mantine/core';
import { useState } from 'react';
import axios from 'axios';
import { IAgregarIndicadorModal } from './AgregarIndicadorModal.interface';
import styles from './AgregarIndicadorModal.module.scss';
import classNames from 'classnames';

const AgregarIndicadorModal = ({ showModal, onCloseModal }: IAgregarIndicadorModal) => {
  const [hasError, setHasError] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState<string | null>(null);
  const [nombre, setNombre] = useState('');
  const [valorMaximo, setValorMaximo] = useState('');
  const [valorDefecto, setValorDefecto] = useState('');
  const [ponderacion, setPonderacion] = useState('');

  const dimensions = [
    { value: '3', label: 'Ser humano' },
    { value: '2', label: 'Estado' },
    { value: '1', label: 'Naturaleza' }
  ];

  let qs = require('qs');
  let data = qs.stringify({
    f: 'crearIndicador',
    nombre: nombre,
    valorMax: valorMaximo,
    valor: valorDefecto,
    ponderacion: ponderacion,
    idDimension: selectedDimension
  });

  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  const handleOnAdd = async () => {
    if (!nombre || !valorDefecto || !valorMaximo || !ponderacion || !selectedDimension) setHasError(true);
    else {
      await axios
        .post(ENDPOINT_SUBMIT || '', data, {
          // headers: headers
        })
        .then((response: any) => {
          window.location.reload();
        })
        .catch((error: any) => {
          console.log(error);
        });
      onCloseModal();
    }
  };

  return (
    <Modal
      centered
      size={496}
      opened={showModal}
      onClose={onCloseModal}
      title="Agregar Indicador"
      styles={{
        modal: { borderRadius: 16 },
        title: {
          paddingLeft: '30px',
          width: '100%',
          textAlign: 'center',
          fontFamily: 'Quicksand',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          marginTop: '20px',
          marginBottom: '13px'
        }
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.username}>Ingrese el nombre del indicador:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Nombre"
              type="text"
              radius={12}
              value={nombre}
              onChange={(e) => {
                setHasError(false);
                setNombre(e.target.value);
              }}
              className={styles.input}
            />
          </div>
          <div className={styles.username}>Ingrese el valor máximo:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Valor Máximo"
              type="text"
              radius={12}
              value={valorMaximo}
              onChange={(e) => {
                setHasError(false);
                setValorMaximo(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>
          <div className={styles.username}>Ingrese el valor por defecto:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Valor por defecto"
              type="text"
              radius={12}
              value={valorDefecto}
              onChange={(e) => {
                setHasError(false);
                setValorDefecto(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>
          <div className={styles.username}>Ingrese la ponderación:</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Ponderación"
              type="text"
              radius={12}
              value={ponderacion}
              onChange={(e) => {
                setHasError(false);
                setPonderacion(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
            />
          </div>
          <div className={styles.username}>Seleccione la Dimensión:</div>
          <div className={styles.inputContainer}>
            <Select
              radius={12}
              placeholder="Seleccione una dimensión"
              className={styles.input}
              data={dimensions}
              value={selectedDimension}
              onChange={setSelectedDimension}
            />
          </div>
        </div>
        {hasError && <div style={{ color: 'red' }}> Error en los datos ingresados</div>}
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={onCloseModal}>
            Cancelar
          </button>
          <button
            className={classNames(styles.loginButton, {
              [styles.disabled]: !nombre || !valorDefecto || !valorMaximo || !ponderacion || !selectedDimension
            })}
            onClick={handleOnAdd}
          >
            Crear Indicador
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AgregarIndicadorModal;
