import React from 'react';
import styles from './QuienesSomos.module.scss';
import quienesSomos from '../../../../assets/quienes-somos.png';
export const QuienesSomos = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}> ¿Quiénes somos?</div>
      <div className={styles.content}>
        <img src={quienesSomos} alt="quienes-somos" className={styles.image} />
        <div className={styles.introduction}>
          Somos un grupo dinámico, profesional y comprometido con la realidad de nuestro entorno. Por ello hemos construido aplicaciones
          prácticas e innovadoras en materia de gobernabilidad, en los ámbitos políticos y de seguridad integral que, mediante el uso de la
          tecnología, proponen soluciones para comprender y transformar el futuro. <br />
          <br />
          La estadística y el big data forman parte fundamental de nuestros sistemas analíticos, que son complementados y fortalecidos con
          el intelecto y profesionalismo de nuestro equipo humano multidisciplinario. <br />
          <br />
          El futuro es hoy y afrontarlo requiere entenderlo de forma estructural, para anticiparnos estratégicamente a los vertiginosos
          cambios y desafíos que plantea el siglo XXI.
        </div>
      </div>
    </div>
  );
};
