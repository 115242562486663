import React from 'react';
import { Footer } from '../../components/UI/Footer/footer';
import { Header } from '../../components/UI/Header/Header';

import styles from './index.module.scss';

const NotFoundPage: React.FC<Record<string, never>> = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.text}>
          PAGINA NO ENCONTRADA
          <button className={styles.button} onClick={() => (window.location.href = '/')}>
            Volver a la página principal
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;
