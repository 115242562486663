import React from 'react';
import { Header } from '../../components/UI/Header/Header';
import { Footer } from '../../components/UI/Footer/footer';
import styles from './index.module.scss';
import { Mapa } from '../../components/UI/Home/Mapa/Mapa';

const MapaPage: React.FC<Record<string, never>> = () => {
  let content = (
    <>
      <div className={styles.container}>
        <Header />
        <Mapa />
        <Footer />
      </div>
    </>
  );
  return content;
};
export default MapaPage;
