import React from 'react';
import { Modal } from '@mantine/core';
import { ILogoutModal } from './LogoutModal.interface';
import styles from './LogoutModal.module.scss';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-barometec.png';

const LogoutModal = ({ showModal, onCloseModal, onAction }: ILogoutModal) => {
  const navigate = useNavigate();
  const handleOnLogout = () => {
    sessionStorage.removeItem('logged');
    sessionStorage.removeItem('profile');
    localStorage.removeItem('azuay_Naturaleza');
    localStorage.removeItem('azuay_Estado');
    localStorage.removeItem('azuay_SerHumano');
    localStorage.removeItem('azuay_Barometro');
    localStorage.removeItem('bolivar_Naturaleza');
    localStorage.removeItem('bolivar_Estado');
    localStorage.removeItem('bolivar_SerHumano');
    localStorage.removeItem('bolivar_Barometro');

    localStorage.removeItem('canar_Naturaleza');
    localStorage.removeItem('canar_Estado');
    localStorage.removeItem('canar_SerHumano');
    localStorage.removeItem('canar_Barometro');

    localStorage.removeItem('carchi_Naturaleza');
    localStorage.removeItem('carchi_Estado');
    localStorage.removeItem('carchi_SerHumano');
    localStorage.removeItem('carchi_Barometro');

    localStorage.removeItem('chimborazo_Naturaleza');
    localStorage.removeItem('chimborazo_Estado');
    localStorage.removeItem('chimborazo_SerHumano');
    localStorage.removeItem('chimborazo_Barometro');

    localStorage.removeItem('cotopaxi_Naturaleza');
    localStorage.removeItem('cotopaxi_Estado');
    localStorage.removeItem('cotopaxi_SerHumano');
    localStorage.removeItem('cotopaxi_Barometro');

    localStorage.removeItem('elOro_Naturaleza');
    localStorage.removeItem('elOro_Estado');
    localStorage.removeItem('elOro_SerHumano');
    localStorage.removeItem('elOro_Barometro');

    localStorage.removeItem('esmeraldas_Naturaleza');
    localStorage.removeItem('esmeraldas_Estado');
    localStorage.removeItem('esmeraldas_SerHumano');
    localStorage.removeItem('esmeraldas_Barometro');

    localStorage.removeItem('galapagos_Naturaleza');
    localStorage.removeItem('galapagos_Estado');
    localStorage.removeItem('galapagos_SerHumano');
    localStorage.removeItem('galapagos_Barometro');

    localStorage.removeItem('guayas_Naturaleza');
    localStorage.removeItem('guayas_Estado');
    localStorage.removeItem('guayas_SerHumano');
    localStorage.removeItem('guayas_Barometro');

    localStorage.removeItem('imbabura_Naturaleza');
    localStorage.removeItem('imbabura_Estado');
    localStorage.removeItem('imbabura_SerHumano');
    localStorage.removeItem('imbabura_Barometro');

    localStorage.removeItem('loja_Naturaleza');
    localStorage.removeItem('loja_Estado');
    localStorage.removeItem('loja_SerHumano');
    localStorage.removeItem('loja_Barometro');

    localStorage.removeItem('losRios_Naturaleza');
    localStorage.removeItem('losRios_Estado');
    localStorage.removeItem('losRios_SerHumano');
    localStorage.removeItem('losRios_Barometro');

    localStorage.removeItem('manabi_Naturaleza');
    localStorage.removeItem('manabi_Estado');
    localStorage.removeItem('manabi_SerHumano');
    localStorage.removeItem('manabi_Barometro');

    localStorage.removeItem('morona_Naturaleza');
    localStorage.removeItem('morona_Estado');
    localStorage.removeItem('morona_SerHumano');
    localStorage.removeItem('morona_Barometro');

    localStorage.removeItem('napo_Naturaleza');
    localStorage.removeItem('napo_Estado');
    localStorage.removeItem('napo_SerHumano');
    localStorage.removeItem('napo_Barometro');

    localStorage.removeItem('orellana_Naturaleza');
    localStorage.removeItem('orellana_Estado');
    localStorage.removeItem('orellana_SerHumano');
    localStorage.removeItem('orellana_Barometro');

    localStorage.removeItem('pastaza_Naturaleza');
    localStorage.removeItem('pastaza_Estado');
    localStorage.removeItem('pastaza_SerHumano');
    localStorage.removeItem('pastaza_Barometro');

    localStorage.removeItem('pichincha_Naturaleza');
    localStorage.removeItem('pichincha_Estado');
    localStorage.removeItem('pichincha_SerHumano');
    localStorage.removeItem('pichincha_Barometro');

    localStorage.removeItem('santaElena_Naturaleza');
    localStorage.removeItem('santaElena_Estado');
    localStorage.removeItem('santaElena_SerHumano');
    localStorage.removeItem('santaElena_Barometro');

    localStorage.removeItem('santoDomingo_Naturaleza');
    localStorage.removeItem('santoDomingo_Estado');
    localStorage.removeItem('santoDomingo_SerHumano');
    localStorage.removeItem('santoDomingo_Barometro');

    localStorage.removeItem('sucumbios_Naturaleza');
    localStorage.removeItem('sucumbios_Estado');
    localStorage.removeItem('sucumbios_SerHumano');
    localStorage.removeItem('sucumbios_Barometro');

    localStorage.removeItem('tungurahua_Naturaleza');
    localStorage.removeItem('tungurahua_Estado');
    localStorage.removeItem('tungurahua_SerHumano');
    localStorage.removeItem('tungurahua_Barometro');

    localStorage.removeItem('zamora_Naturaleza');
    localStorage.removeItem('zamora_Estado');
    localStorage.removeItem('zamora_SerHumano');
    localStorage.removeItem('zamora_Barometro');
    navigate('/');
    onCloseModal();
  };
  return (
    <Modal
      centered
      size={496}
      opened={showModal}
      onClose={onCloseModal}
      title="Cerrar sesión"
      styles={{
        modal: { borderRadius: 16 },
        title: {
          paddingLeft: '30px',
          width: '100%',
          textAlign: 'center',
          fontFamily: 'Quicksand',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          marginTop: '20px',
          marginBottom: '13px'
        }
      }}
    >
      <div className={styles.modalContainer}>
        <img src={logo} alt="logo" height={100} />
        <div className={styles.logoutContainer}>Está seguo que desea cerrar sesión?</div>
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={onCloseModal}>
            Cancelar
          </button>
          <button className={styles.logoutButton} onClick={handleOnLogout}>
            Cerrar sesión
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
