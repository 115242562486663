import React from 'react';
import styles from './Soluciones.module.scss';
import soluciones from '../../../../assets/soluciones.png';
export const Soluciones = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>¿Cuáles son las soluciones que plantea el Barómetro de Seguridad Integral?</div>
      <div className={styles.introduction}>
        <img src={soluciones} alt="" height={600} />
      </div>
    </div>
  );
};
