import React from 'react';
import styles from './intro.module.scss';
import { Carousel } from '@mantine/carousel';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import front1 from '../../../../assets/front1.png';
import front2 from '../../../../assets/front2.png';
import front3 from '../../../../assets/front3.png';
import front4 from '../../../../assets/front4.png';
import front5 from '../../../../assets/front5.png';

export const Intro = () => {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.content}>
        <div style={{ width: '45%' }}>
          <Carousel
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            sx={{ maxWidth: '100%' }}
            mx="auto"
            withIndicators
            withControls={false}
            height="auto"
            style={{ width: '100%' }}
          >
            <Carousel.Slide>
              <img src={front1} alt="" className={styles.slide} />
            </Carousel.Slide>
            <Carousel.Slide>
              <img src={front2} alt="" className={styles.slide} />
            </Carousel.Slide>
            <Carousel.Slide>
              <img src={front3} alt="" className={styles.slide} />
            </Carousel.Slide>
            <Carousel.Slide>
              <img src={front4} alt="" className={styles.slide} />
            </Carousel.Slide>
            <Carousel.Slide>
              <img src={front5} alt="" className={styles.slide} />
            </Carousel.Slide>
          </Carousel>
        </div>
        <div className={styles.mapContainer}>
          <iframe
            style={{ border: '0', width: '600px', minHeight: '541px', marginLeft: '40px', padding: '30px' }}
            title="Grupo Barometec"
            src="https://datawrapper.dwcdn.net/byrhW/3/"
          ></iframe>
        </div>
      </div>
      <div className={styles.bottomText}>
        {/* <div className={styles.subtitle}>Grupo Barometec</div>
        <div className={styles.description}>
          Somos un grupo dinámico, profesional y comprometido con la realidad de nuestro entorno. Por ello hemos construido aplicaciones
          prácticas e innovadoras en materia de gobernabilidad, en los ámbitos políticos y de seguridad integral que, mediante el uso de la
          tecnología, proponen soluciones para comprender y transformar el futuro. La estadística y el big data forman parte fundamental de
          nuestros sistemas analíticos, que son complementados y fortalecidos con el intelecto y profesionalismo de nuestro equipo humano
          multidisciplinario. El futuro es hoy y afrontarlo requiere entenderlo de forma estructural, para anticiparnos estratégicamente a
          los vertiginosos cambios y desafíos que plantea el siglo XXI
        </div> */}
        {/* <button className={styles.button} onClick={() => navigate('/map.html')}>
          Abrir mapa interactivo
        </button> */}
      </div>
    </div>
  );
};
