import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface IndicatorsData {
  id_indicador: string;
  id_barometro: string;
  nombre: string;
  id_dimension: string;
  valor_max: string;
  valor: string;
  ponderacion: string;
}
interface Props {
  data: IndicatorsData | undefined;
  onUpdatedId: (id: string) => void;
}

export const IndicatorNode = ({ data, onUpdatedId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValor, setInputValor] = useState('');
  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  const handleOnEdit = () => {
    setIsEditing(true);
  };
  const handleOnSave = () => {
    onUpdatedId('0');

    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'actualizarBarometro',
      idBarometro: data?.id_barometro,
      valor: inputValor
    });

    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {})
      .then(function (response) {
        onUpdatedId(data!.id_barometro);
        setIsEditing(false);
        calcularDesviacionMedia();
        calcularValoresProvincias();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const calcularDesviacionMedia = async () => {
    var qs = require('qs');
    var dataquery = qs.stringify({
      f: 'calcularDesviacionMedia'
    });
    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const calcularValoresProvincias = () => {
    var qs = require('qs');
    var dataquery = qs.stringify({
      f: 'calcularValoresProvincias'
    });
    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    setInputValor(data!.valor);
  }, [data, data?.valor]);

  return (
    <>
      <div>
        <div className={styles.content}>
          <div className={styles.id}>{data?.id_barometro}</div>
          <div className={styles.nombre}>{data?.nombre}</div> <div className={styles.valorMaximo}>{Number(data?.valor_max)}</div>
          <div className={styles.ponderacion}>{data?.ponderacion}</div>
          {isEditing ? (
            <div className={styles.inputContainer}>
              <input className={styles.input} value={inputValor} onChange={(e) => setInputValor(e.target.value)} />
            </div>
          ) : (
            <div className={styles.valor}>{data?.valor}</div>
          )}
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={isEditing ? handleOnSave : handleOnEdit}>
              {isEditing ? 'Guardar' : 'Modificar'}
            </button>
          </div>
          <div className="flex flex-center">
            <div className="w-full flex flex-row space-around"></div>
          </div>
        </div>
      </div>
    </>
  );
};
