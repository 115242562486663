import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select } from '@mantine/core';
import { Stickynote, NoteRemove } from 'iconsax-react';
import EliminarIndicadorModal from '../../UI/Admin/AdminIndicadores/EliminarIndicadorModal/EliminarIndicadorModal';

interface IndicatorsData {
  id_indicador: string;
  nombre: string;
  id_dimension: string;
  valor_max: string;
  valor: string;
  ponderacion: string;
}
interface Props {
  data: IndicatorsData | undefined;
  onUpdatedId: (id: string) => void;
}

export const IndicatorNode = ({ data, onUpdatedId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputNombre, setInputNombre] = useState('');
  const [inputValorMaximo, setInputValorMaximo] = useState('');
  const [inputValor, setInputValor] = useState('');
  const [inputPonderacion, setInputPonderacion] = useState('');
  const [inputDimension, setInputDimension] = useState('1');
  const [showDeleteIndicadorModal, setShowDeleteIndicadorModal] = useState(false);

  const dimensions = [
    { value: '3', label: 'Ser humano' },
    { value: '2', label: 'Estado' },
    { value: '1', label: 'Naturaleza' }
  ];
  const handleOnEdit = () => {
    setIsEditing(true);
  };

  const handleOnSave = () => {
    onUpdatedId('0');
    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'actualizarIndicador',
      idIndicador: data?.id_indicador,
      nombre: inputNombre,
      valorMax: inputValorMaximo,
      valor: inputValor,
      ponderacion: inputPonderacion,
      idDimension: inputDimension
    });
    const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {})
      .then(function (response) {
        onUpdatedId(data!.id_indicador);
        console.log(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsEditing(false);
  };

  useEffect(() => {
    setInputNombre(data!.nombre);
  }, [data, data?.nombre]);
  useEffect(() => {
    setInputValor(data!.valor);
  }, [data, data?.valor]);
  useEffect(() => {
    setInputValorMaximo(data!.valor_max);
  }, [data, data?.valor_max]);
  useEffect(() => {
    setInputPonderacion(data!.ponderacion);
  }, [data, data?.ponderacion]);
  useEffect(() => {
    setInputDimension(data!.id_dimension);
  }, [data, data?.id_dimension]);

  return (
    <>
      {isEditing ? (
        <div className={styles.content}>
          <div className={styles.id}>{data?.id_indicador}</div>
          <input className={styles.inputNombre} value={inputNombre} onChange={(e) => setInputNombre(e.target.value)} />
          <input className={styles.inputValorMaximo} value={inputValorMaximo} onChange={(e) => setInputValorMaximo(e.target.value)} />
          <input className={styles.inputValor} value={inputValor} onChange={(e) => setInputValor(e.target.value)} />
          <input className={styles.inputPonderacion} value={inputPonderacion} onChange={(e) => setInputPonderacion(e.target.value)} />
          <Select
            placeholder="Seleccione una dimensión"
            data={dimensions}
            radius={12}
            value={inputDimension}
            onChange={(value) => setInputDimension(value ? value : '1')}
            className={styles.inputDimension}
          />

          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={isEditing ? handleOnSave : handleOnEdit}>
              {isEditing ? 'Guardar' : 'Modificar'}
            </button>
          </div>
          {/* <div className={styles.buttonContainer}>
            <button className={classNames(styles.button, { [styles.saveButton]: isEditing })} onClick={() => setIsEditing(false)}>
              Cancelar
            </button>
          </div> */}
        </div>
      ) : (
        <div>
          <EliminarIndicadorModal
            idIndicador={data!.id_indicador}
            nombreIndicador={data!.nombre}
            showModal={showDeleteIndicadorModal}
            onCloseModal={() => {
              setShowDeleteIndicadorModal(false);
            }}
          />
          <div className={styles.content}>
            <div className={styles.id}>{data?.id_indicador}</div> <div className={styles.nombre}>{data?.nombre}</div>{' '}
            <div className={styles.valorMaximo}>{Number(data?.valor_max)}</div>
            <div className={styles.valor}>{data?.valor}</div>
            <div className={styles.ponderacion}>{data?.ponderacion}</div>
            <div className={styles.dimension}>{dimensions.find((item) => item.value === data?.id_dimension)?.label}</div>
            <div className={styles.buttonContainer}>
              <Stickynote className={styles.icon} id="Editar" onClick={isEditing ? handleOnSave : handleOnEdit} />
              <NoteRemove className={styles.icon} onClick={() => setShowDeleteIndicadorModal(true)} />
            </div>
            <div className="flex flex-center">
              <div className="w-full flex flex-row space-around"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
