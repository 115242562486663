import React from 'react';
import styles from './Mapa.module.scss';
const mapa = 'https://grupobarometec.com/map/map.html';
// const mapa = '../../../../../pages/jsmaps/map.html';

let azuay_Naturaleza, azuay_Estado, azuay_SerHumano;
let bolivar_Naturaleza, bolivar_Estado, bolivar_SerHumano;
let canar_Naturaleza, canar_Estado, canar_SerHumano;
let carchi_Naturaleza, carchi_Estado, carchi_SerHumano;
let chimborazo_Naturaleza, chimborazo_Estado, chimborazo_SerHumano;
let cotopaxi_Naturaleza, cotopaxi_Estado, cotopaxi_SerHumano;
let elOro_Naturaleza, elOro_Estado, elOro_SerHumano;
let esmeraldas_Naturaleza, esmeraldas_Estado, esmeraldas_SerHumano;
let galapagos_Naturaleza, galapagos_Estado, galapagos_SerHumano;
let guayas_Naturaleza, guayas_Estado, guayas_SerHumano;
let imbabura_Naturaleza, imbabura_Estado, imbabura_SerHumano;
let loja_Naturaleza, loja_Estado, loja_SerHumano;
let losRios_Naturaleza, losRios_Estado, losRios_SerHumano;
let manabi_Naturaleza, manabi_Estado, manabi_SerHumano;
let morona_Naturaleza, morona_Estado, morona_SerHumano;
let napo_Naturaleza, napo_Estado, napo_SerHumano;
let sucumbios_Naturaleza, sucumbios_Estado, sucumbios_SerHumano;
let pastaza_Naturaleza, pastaza_Estado, pastaza_SerHumano;
let pichincha_Naturaleza, pichincha_Estado, pichincha_SerHumano;
let santaElena_Naturaleza, santaElena_Estado, santaElena_SerHumano;
let santoDomingo_Naturaleza, santoDomingo_Estado, santoDomingo_SerHumano;
let orellana_Naturaleza, orellana_Estado, orellana_SerHumano;
let tungurahua_Naturaleza, tungurahua_Estado, tungurahua_SerHumano;
let zamora_Naturaleza, zamora_Estado, zamora_SerHumano;

let azuay_Barometro;
let bolivar_Barometro;
let canar_Barometro;
let carchi_Barometro;
let chimborazo_Barometro;
let cotopaxi_Barometro;
let elOro_Barometro;
let esmeraldas_Barometro;
let galapagos_Barometro;
let guayas_Barometro;
let imbabura_Barometro;
let loja_Barometro;
let losRios_Barometro;
let manabi_Barometro;
let morona_Barometro;
let napo_Barometro;
let orellana_Barometro;
let pastaza_Barometro;
let pichincha_Barometro;
let santaElena_Barometro;
let santoDomingo_Barometro;
let sucumbios_Barometro;
let tungurahua_Barometro;
let zamora_Barometro;

export const Mapa = () => {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  var urlencoded = new URLSearchParams();
  urlencoded.append('f', 'obtenerDatosBarometro');

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded
    // redirect: 'follow'
  };

  const getData = async (requestOptions: any) => {
    const response = await fetch('https://grupobarometec.com/barometroBack/barometroWS.php', requestOptions);
    const data = await response.json();

    azuay_Naturaleza = Number(data[0][0].valor).toFixed(2);
    azuay_Estado = Number(data[0][1].valor).toFixed(2);
    azuay_SerHumano = Number(data[0][2].valor).toFixed(2);
    bolivar_Naturaleza = Number(data[0][3].valor).toFixed(2);
    bolivar_Estado = Number(data[0][4].valor).toFixed(2);
    bolivar_SerHumano = Number(data[0][5].valor).toFixed(2);
    canar_Naturaleza = Number(data[0][6].valor).toFixed(2);
    canar_Estado = Number(data[0][7].valor).toFixed(2);
    canar_SerHumano = Number(data[0][8].valor).toFixed(2);
    carchi_Naturaleza = Number(data[0][9].valor).toFixed(2);
    carchi_Estado = Number(data[0][10].valor).toFixed(2);
    carchi_SerHumano = Number(data[0][11].valor).toFixed(2);
    chimborazo_Naturaleza = Number(data[0][12].valor).toFixed(2);
    chimborazo_Estado = Number(data[0][13].valor).toFixed(2);
    chimborazo_SerHumano = Number(data[0][14].valor).toFixed(2);
    cotopaxi_Naturaleza = Number(data[0][15].valor).toFixed(2);
    cotopaxi_Estado = Number(data[0][16].valor).toFixed(2);
    cotopaxi_SerHumano = Number(data[0][17].valor).toFixed(2);
    elOro_Naturaleza = Number(data[0][18].valor).toFixed(2);
    elOro_Estado = Number(data[0][19].valor).toFixed(2);
    elOro_SerHumano = Number(data[0][20].valor).toFixed(2);
    esmeraldas_Naturaleza = Number(data[0][21].valor).toFixed(2);
    esmeraldas_Estado = Number(data[0][22].valor).toFixed(2);
    esmeraldas_SerHumano = Number(data[0][23].valor).toFixed(2);
    galapagos_Naturaleza = Number(data[0][24].valor).toFixed(2);
    galapagos_Estado = Number(data[0][25].valor).toFixed(2);
    galapagos_SerHumano = Number(data[0][26].valor).toFixed(2);
    guayas_Naturaleza = Number(data[0][27].valor).toFixed(2);
    guayas_Estado = Number(data[0][28].valor).toFixed(2);
    guayas_SerHumano = Number(data[0][29].valor).toFixed(2);
    imbabura_Naturaleza = Number(data[0][30].valor).toFixed(2);
    imbabura_Estado = Number(data[0][31].valor).toFixed(2);
    imbabura_SerHumano = Number(data[0][32].valor).toFixed(2);
    loja_Naturaleza = Number(data[0][33].valor).toFixed(2);
    loja_Estado = Number(data[0][34].valor).toFixed(2);
    loja_SerHumano = Number(data[0][35].valor).toFixed(2);
    losRios_Naturaleza = Number(data[0][36].valor).toFixed(2);
    losRios_Estado = Number(data[0][37].valor).toFixed(2);
    losRios_SerHumano = Number(data[0][38].valor).toFixed(2);
    manabi_Naturaleza = Number(data[0][40].valor).toFixed(2);
    manabi_Estado = Number(data[0][39].valor).toFixed(2);
    manabi_SerHumano = Number(data[0][41].valor).toFixed(2);
    morona_Naturaleza = Number(data[0][42].valor).toFixed(2);
    morona_Estado = Number(data[0][43].valor).toFixed(2);
    morona_SerHumano = Number(data[0][44].valor).toFixed(2);
    napo_Naturaleza = Number(data[0][45].valor).toFixed(2);
    napo_Estado = Number(data[0][46].valor).toFixed(2);
    napo_SerHumano = Number(data[0][47].valor).toFixed(2);
    orellana_Naturaleza = Number(data[0][48].valor).toFixed(2);
    orellana_Estado = Number(data[0][49].valor).toFixed(2);
    orellana_SerHumano = Number(data[0][51].valor).toFixed(2);
    pastaza_Naturaleza = Number(data[0][50].valor).toFixed(2);
    pastaza_Estado = Number(data[0][52].valor).toFixed(2);
    pastaza_SerHumano = Number(data[0][53].valor).toFixed(2);
    pichincha_Naturaleza = Number(data[0][55].valor).toFixed(2);
    pichincha_Estado = Number(data[0][56].valor).toFixed(2);
    pichincha_SerHumano = Number(data[0][54].valor).toFixed(2);
    santaElena_Naturaleza = Number(data[0][58].valor).toFixed(2);
    santaElena_Estado = Number(data[0][59].valor).toFixed(2);
    santaElena_SerHumano = Number(data[0][57].valor).toFixed(2);
    santoDomingo_Naturaleza = Number(data[0][61].valor).toFixed(2);
    santoDomingo_Estado = Number(data[0][62].valor).toFixed(2);
    santoDomingo_SerHumano = Number(data[0][60].valor).toFixed(2);
    sucumbios_Naturaleza = Number(data[0][64].valor).toFixed(2);
    sucumbios_Estado = Number(data[0][65].valor).toFixed(2);
    sucumbios_SerHumano = Number(data[0][63].valor).toFixed(2);
    tungurahua_Naturaleza = Number(data[0][67].valor).toFixed(2);
    tungurahua_Estado = Number(data[0][68].valor).toFixed(2);
    tungurahua_SerHumano = Number(data[0][66].valor).toFixed(2);
    zamora_Naturaleza = Number(data[0][69].valor).toFixed(2);
    zamora_Estado = Number(data[0][70].valor).toFixed(2);
    zamora_SerHumano = Number(data[0][71].valor).toFixed(2);

    azuay_Barometro = (Number(azuay_Estado) + Number(azuay_Naturaleza) + Number(azuay_SerHumano)).toFixed(2);
    bolivar_Barometro = (Number(bolivar_Estado) + Number(bolivar_Naturaleza) + Number(bolivar_SerHumano)).toFixed(2);
    canar_Barometro = (Number(canar_Estado) + Number(canar_Naturaleza) + Number(canar_SerHumano)).toFixed(2);
    carchi_Barometro = (Number(carchi_Estado) + Number(carchi_Naturaleza) + Number(carchi_SerHumano)).toFixed(2);
    chimborazo_Barometro = (Number(chimborazo_Estado) + Number(chimborazo_Naturaleza) + Number(chimborazo_SerHumano)).toFixed(2);
    cotopaxi_Barometro = (Number(cotopaxi_Estado) + Number(cotopaxi_Naturaleza) + Number(cotopaxi_SerHumano)).toFixed(2);
    elOro_Barometro = (Number(elOro_Estado) + Number(elOro_Naturaleza) + Number(elOro_SerHumano)).toFixed(2);
    esmeraldas_Barometro = (Number(esmeraldas_Estado) + Number(esmeraldas_Naturaleza) + Number(esmeraldas_SerHumano)).toFixed(2);
    galapagos_Barometro = (Number(galapagos_Estado) + Number(galapagos_Naturaleza) + Number(galapagos_SerHumano)).toFixed(2);
    guayas_Barometro = (Number(guayas_Estado) + Number(guayas_Naturaleza) + Number(guayas_SerHumano)).toFixed(2);
    imbabura_Barometro = (Number(imbabura_Estado) + Number(imbabura_Naturaleza) + Number(imbabura_SerHumano)).toFixed(2);
    loja_Barometro = (Number(loja_Estado) + Number(loja_Naturaleza) + Number(loja_SerHumano)).toFixed(2);
    losRios_Barometro = (Number(losRios_Estado) + Number(losRios_Naturaleza) + Number(losRios_SerHumano)).toFixed(2);
    manabi_Barometro = (Number(manabi_Estado) + Number(manabi_Naturaleza) + Number(manabi_SerHumano)).toFixed(2);
    morona_Barometro = (Number(morona_Estado) + Number(morona_Naturaleza) + Number(morona_SerHumano)).toFixed(2);
    napo_Barometro = (Number(napo_Estado) + Number(napo_Naturaleza) + Number(napo_SerHumano)).toFixed(2);
    orellana_Barometro = (Number(orellana_Estado) + Number(orellana_Naturaleza) + Number(orellana_SerHumano)).toFixed(2);
    pastaza_Barometro = (Number(pastaza_Estado) + Number(pastaza_Naturaleza) + Number(pastaza_SerHumano)).toFixed(2);
    pichincha_Barometro = (Number(pichincha_Estado) + Number(pichincha_Naturaleza) + Number(pichincha_SerHumano)).toFixed(2);
    santaElena_Barometro = (Number(santaElena_Estado) + Number(santaElena_Naturaleza) + Number(santaElena_SerHumano)).toFixed(2);
    santoDomingo_Barometro = (Number(santoDomingo_Estado) + Number(santoDomingo_Naturaleza) + Number(santoDomingo_SerHumano)).toFixed(2);
    sucumbios_Barometro = (Number(sucumbios_Estado) + Number(sucumbios_Naturaleza) + Number(sucumbios_SerHumano)).toFixed(2);
    tungurahua_Barometro = (Number(tungurahua_Estado) + Number(tungurahua_Naturaleza) + Number(tungurahua_SerHumano)).toFixed(2);
    zamora_Barometro = (Number(zamora_Estado) + Number(zamora_Naturaleza) + Number(zamora_SerHumano)).toFixed(2);

    localStorage.setItem('azuay_Naturaleza', azuay_Naturaleza);
    localStorage.setItem('azuay_Estado', azuay_Estado);
    localStorage.setItem('azuay_SerHumano', azuay_SerHumano);
    localStorage.setItem('azuay_Barometro', azuay_Barometro);

    localStorage.setItem('bolivar_Naturaleza', bolivar_Naturaleza);
    localStorage.setItem('bolivar_Estado', bolivar_Estado);
    localStorage.setItem('bolivar_SerHumano', bolivar_SerHumano);
    localStorage.setItem('bolivar_Barometro', bolivar_Barometro);

    localStorage.setItem('canar_Naturaleza', canar_Naturaleza);
    localStorage.setItem('canar_Estado', canar_Estado);
    localStorage.setItem('canar_SerHumano', canar_SerHumano);
    localStorage.setItem('canar_Barometro', canar_Barometro);

    localStorage.setItem('carchi_Naturaleza', carchi_Naturaleza);
    localStorage.setItem('carchi_Estado', carchi_Estado);
    localStorage.setItem('carchi_SerHumano', carchi_SerHumano);
    localStorage.setItem('carchi_Barometro', carchi_Barometro);

    localStorage.setItem('chimborazo_Naturaleza', chimborazo_Naturaleza);
    localStorage.setItem('chimborazo_Estado', chimborazo_Estado);
    localStorage.setItem('chimborazo_SerHumano', chimborazo_SerHumano);
    localStorage.setItem('chimborazo_Barometro', chimborazo_Barometro);

    localStorage.setItem('cotopaxi_Naturaleza', cotopaxi_Naturaleza);
    localStorage.setItem('cotopaxi_Estado', cotopaxi_Estado);
    localStorage.setItem('cotopaxi_SerHumano', cotopaxi_SerHumano);
    localStorage.setItem('cotopaxi_Barometro', cotopaxi_Barometro);

    localStorage.setItem('elOro_Naturaleza', elOro_Naturaleza);
    localStorage.setItem('elOro_Estado', elOro_Estado);
    localStorage.setItem('elOro_SerHumano', elOro_SerHumano);
    localStorage.setItem('elOro_Barometro', elOro_Barometro);

    localStorage.setItem('esmeraldas_Naturaleza', esmeraldas_Naturaleza);
    localStorage.setItem('esmeraldas_Estado', esmeraldas_Estado);
    localStorage.setItem('esmeraldas_SerHumano', esmeraldas_SerHumano);
    localStorage.setItem('esmeraldas_Barometro', esmeraldas_Barometro);

    localStorage.setItem('galapagos_Naturaleza', galapagos_Naturaleza);
    localStorage.setItem('galapagos_Estado', galapagos_Estado);
    localStorage.setItem('galapagos_SerHumano', galapagos_SerHumano);
    localStorage.setItem('galapagos_Barometro', galapagos_Barometro);

    localStorage.setItem('guayas_Naturaleza', guayas_Naturaleza);
    localStorage.setItem('guayas_Estado', guayas_Estado);
    localStorage.setItem('guayas_SerHumano', guayas_SerHumano);
    localStorage.setItem('guayas_Barometro', guayas_Barometro);

    localStorage.setItem('imbabura_Naturaleza', imbabura_Naturaleza);
    localStorage.setItem('imbabura_Estado', imbabura_Estado);
    localStorage.setItem('imbabura_SerHumano', imbabura_SerHumano);
    localStorage.setItem('imbabura_Barometro', imbabura_Barometro);

    localStorage.setItem('loja_Naturaleza', loja_Naturaleza);
    localStorage.setItem('loja_Estado', loja_Estado);
    localStorage.setItem('loja_SerHumano', loja_SerHumano);
    localStorage.setItem('loja_Barometro', loja_Barometro);

    localStorage.setItem('losRios_Naturaleza', losRios_Naturaleza);
    localStorage.setItem('losRios_Estado', losRios_Estado);
    localStorage.setItem('losRios_SerHumano', losRios_SerHumano);
    localStorage.setItem('losRios_Barometro', losRios_Barometro);

    localStorage.setItem('manabi_Naturaleza', manabi_Naturaleza);
    localStorage.setItem('manabi_Estado', manabi_Estado);
    localStorage.setItem('manabi_SerHumano', manabi_SerHumano);
    localStorage.setItem('manabi_Barometro', manabi_Barometro);

    localStorage.setItem('morona_Naturaleza', morona_Naturaleza);
    localStorage.setItem('morona_Estado', morona_Estado);
    localStorage.setItem('morona_SerHumano', morona_SerHumano);
    localStorage.setItem('morona_Barometro', morona_Barometro);

    localStorage.setItem('napo_Naturaleza', napo_Naturaleza);
    localStorage.setItem('napo_Estado', napo_Estado);
    localStorage.setItem('napo_SerHumano', napo_SerHumano);
    localStorage.setItem('napo_Barometro', napo_Barometro);

    localStorage.setItem('orellana_Naturaleza', orellana_Naturaleza);
    localStorage.setItem('orellana_Estado', orellana_Estado);
    localStorage.setItem('orellana_SerHumano', orellana_SerHumano);
    localStorage.setItem('orellana_Barometro', orellana_Barometro);

    localStorage.setItem('pastaza_Naturaleza', pastaza_Naturaleza);
    localStorage.setItem('pastaza_Estado', pastaza_Estado);
    localStorage.setItem('pastaza_SerHumano', pastaza_SerHumano);
    localStorage.setItem('pastaza_Barometro', pastaza_Barometro);

    localStorage.setItem('pichincha_Naturaleza', pichincha_Naturaleza);
    localStorage.setItem('pichincha_Estado', pichincha_Estado);
    localStorage.setItem('pichincha_SerHumano', pichincha_SerHumano);
    localStorage.setItem('pichincha_Barometro', pichincha_Barometro);

    localStorage.setItem('santaElena_Naturaleza', santaElena_Naturaleza);
    localStorage.setItem('santaElena_Estado', santaElena_Estado);
    localStorage.setItem('santaElena_SerHumano', santaElena_SerHumano);
    localStorage.setItem('santaElena_Barometro', santaElena_Barometro);

    localStorage.setItem('santoDomingo_Naturaleza', santoDomingo_Naturaleza);
    localStorage.setItem('santoDomingo_Estado', santoDomingo_Estado);
    localStorage.setItem('santoDomingo_SerHumano', santoDomingo_SerHumano);
    localStorage.setItem('santoDomingo_Barometro', santoDomingo_Barometro);

    localStorage.setItem('sucumbios_Naturaleza', sucumbios_Naturaleza);
    localStorage.setItem('sucumbios_Estado', sucumbios_Estado);
    localStorage.setItem('sucumbios_SerHumano', sucumbios_SerHumano);
    localStorage.setItem('sucumbios_Barometro', sucumbios_Barometro);

    localStorage.setItem('tungurahua_Naturaleza', tungurahua_Naturaleza);
    localStorage.setItem('tungurahua_Estado', tungurahua_Estado);
    localStorage.setItem('tungurahua_SerHumano', tungurahua_SerHumano);
    localStorage.setItem('tungurahua_Barometro', tungurahua_Barometro);

    localStorage.setItem('zamora_Naturaleza', zamora_Naturaleza);
    localStorage.setItem('zamora_Estado', zamora_Estado);
    localStorage.setItem('zamora_SerHumano', zamora_SerHumano);
    localStorage.setItem('zamora_Barometro', zamora_Barometro);
  };

  getData(requestOptions);

  //
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>Mapa por provincias</div>
      <div className={styles.content}>
        <div className={styles.introduction} style={{ minHeight: '600px', marginTop: '50px' }}>
          <iframe style={{ border: 'none' }} title="mapa" src={mapa} height={900} width={1400} />
        </div>
      </div>
    </div>
  );
};
