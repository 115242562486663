import styles from './index.module.scss'

export const DescArrows = () => {
  return (
    <div>
      <div className={styles.arrowUp} style={{ marginBottom: '0.5rem' }} />
      <div className={styles.arrowDownHighlight} />
    </div>
  )
}
