import React from 'react';
import styles from './Historia.module.scss';
import historia from '../../../../assets/breve-historia.png';

export const Historia = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}> Breve historia de nosotros</div>
      <div className={styles.content}>
        <img src={historia} alt="breve-historia-de-nosotros" className={styles.image} />
        <div className={styles.introduction}>
          El Grupo Barometec nace en Quito, Ecuador, a inicios del año 2022. Fue conformado por un grupo de jóvenes académicos y
          profesionales, consientes y críticos con la realidad y problemas que aquejan al país, en materia de gobernabilidad. Ante ello
          decidimos aunar esfuerzos desde nuestros diversos espacios de formación y experiencia, para construir aplicaciones (APP) que
          contribuyan a comprender las condiciones de estabilidad y seguridad integral en el Ecuador. Sin embargo, estas propuestas
          mantienen una lógica abierta de ser moldeadas y adaptables a la realidad vecinal y regional. Por ello que el Grupo Barometec es
          dinámico y de él ¡puedes formar tú!
        </div>
      </div>
    </div>
  );
};
