import React from 'react';
import styles from './footer.module.scss';
import facebookIcon from '../../../assets/social-icons/facebook-icon.png';
import twitterIcon from '../../../assets/social-icons/twitter-icon.png';
import tiktokIcon from '../../../assets/social-icons/tiktok-icon.png';
import youtubeIcon from '../../../assets/social-icons/youtube-icon.png';
import mailIcon from '../../../assets/social-icons/mail-icon.png';
import whatsappIcon from '../../../assets/social-icons/whatsapp-icon.png';
import telegramIcon from '../../../assets/social-icons/telegram-icon.png';
import logo from '../../../assets/logo-barometec.png';

export const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}></div>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={logo} alt="" height={80} />
        </div>
        <div className={styles.rigthItems}>
          <div className={styles.socialIcons}>
            <img
              src={facebookIcon}
              alt="facebook"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://www.facebook.com/people/Grupo-Barometec/100090177837316/');
              }}
            />
            <img
              src={twitterIcon}
              alt="Twitter"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://twitter.com/grupo_barometec');
              }}
            />
            <img
              src={tiktokIcon}
              alt="TikTok"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://www.tiktok.com/@grupobarometec');
              }}
            />
            <img
              src={youtubeIcon}
              alt="Youtube"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://www.youtube.com/@grupobarometec');
              }}
            />
            <a href="mailto:barometec@gmail.com" target="_blank" rel="noreferrer">
              <img src={mailIcon} alt="Correo" className={styles.socialIcon} />
            </a>
            <img
              src={whatsappIcon}
              alt="WhatsApp"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://wa.link/5nyh1z');
              }}
            />{' '}
            <img
              src={telegramIcon}
              alt="Telegram"
              className={styles.socialIcon}
              onClick={() => {
                window.open('https://msng.link/o/?Barometec=tg');
              }}
            />
          </div>
          <div className={styles.copyright}>Copyright ©2023. Todos los derechos reservados</div>
        </div>
      </div>
    </div>
  );
};
