import React from 'react';
import { Header } from '../../components/UI/Header/Header';
import { Footer } from '../../components/UI/Footer/footer';
import { Intro } from '../../components/UI/Home/Intro/intro';
import styles from './index.module.scss';
import { Helmet } from 'react-helmet';

const Home: React.FC<Record<string, never>> = () => {
  let content = (
    <>
      <div className={styles.container}>
        <Helmet>
          <title>Barómetro de la Seguridad Integral del Ecuador</title>
          <meta name="description" content="Barómetro de la Seguridad Integral del Ecuador, Grupo Barometec" />
          <meta
            name="keywords"
            content="barometec, barometro ecuador, ecuador, grupo barometec, barometro de seguridad, seguridad ecuador, indice de seguridad"
          />
        </Helmet>
        <Header />
        <Intro />
        <Footer />
      </div>
    </>
  );

  return content;
};
export default Home;
