import React from 'react';
import styles from './AdminDimensionesPage.module.scss';
import { AdminDimension } from '../../../components/UI/Admin/AdminDimension/AdminDimension';
import { Header } from '../../../components/UI/Header/Header';
import { Footer } from '../../../components/UI/Footer/footer';

const AdminDimensionesPage: React.FC<Record<string, never>> = () => {
  let content = (
    <>
      <div className={styles.container}>
        <Header />
        <div style={{ minHeight: '600px' }}>
          <AdminDimension />
        </div>
        <Footer />
      </div>
    </>
  );

  return content;
};
export default AdminDimensionesPage;
