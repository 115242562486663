import React from 'react';
import styles from './Objetivos.module.scss';
import objetivos from '../../../../assets/objetivos.png';

export const Objetivos = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>Objetivos</div>
      <div className={styles.content}>
        <div className={styles.introduction}>
          <p style={{ fontStyle: 'italic', fontSize: '24px' }}>General</p>
          Crear sistemas analíticos de gobernabilidad, mediante la utilización de big data, que sirvan de asesoramiento para la toma de
          decisiones y el mejoramiento de las condiciones sociales y de seguridad integral del país.
        </div>
        <img src={objetivos} alt="mision" className={styles.image} />
      </div>
      <div>
        <ul style={{ fontStyle: 'italic', fontSize: '24px' }}>Específicos</ul>
        <li style={{ marginBottom: '45px' }}>
          Identificar los datos y factores que alteran la estabilidad política y seguridad integral del Estado, para anticiparse
          estratégicamente a sus sucesos.
        </li>
        <li style={{ marginBottom: '45px' }}>
          Elaborar análisis estructurales y sistémicos en materia de gobernabilidad, fundamentados en los datos previamente identificados y
          levantados.
        </li>

        <li style={{ marginBottom: '45px' }}>
          Socializar la información estratégica consolidada, con los públicos internos y externos del Grupo Barometec.
        </li>
      </div>
    </div>
  );
};
