import React from 'react';
import styles from './Barometro.module.scss';
import barometro from '../../../../assets/barometro-seguridad-integral.png';

export const Barometro = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>¿De qué se trata?</div>
      <div className={styles.content}>
        <div className={styles.introduction}>
          Sistema informático (APP) de gobernabilidad que, mediante una medida integrada, determina el grado de seguridad/inseguridad en las
          dimensiones de la seguridad integral: antropocéntrica (ser humano), biocéntrica (naturaleza) y estadocéntrica (Estado), en las 24
          provincias del Ecuador.
        </div>
        <img src={barometro} alt="barometro-de-seguridad-integral" className={styles.image} />
      </div>
    </div>
  );
};
