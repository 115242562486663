import React from 'react';
import { Modal } from '@mantine/core';
import axios from 'axios';
import { IEliminarUsuarioModal } from './EliminarUsuarioModal.interface';
import styles from './EliminarUsuarioModal.module.scss';

const EliminarUsuarioModal = ({ showModal, onCloseModal, idUsuario, nombreUsuario }: IEliminarUsuarioModal) => {
  const handleOnRemove = () => {
    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'eliminarUsuario',
      idUsuario: idUsuario
    });
    const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

    axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then(function (response) {
        onCloseModal();
        window.location.reload();
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnCancel = () => {
    onCloseModal();
  };
  return (
    <Modal
      centered
      size={496}
      opened={showModal}
      onClose={onCloseModal}
      title="Eliminar Usuario"
      styles={{
        modal: { borderRadius: 16 },
        title: {
          paddingLeft: '30px',
          width: '100%',
          textAlign: 'center',
          fontFamily: 'Quicksand',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          marginTop: '20px',
          marginBottom: '13px'
        }
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.username}>
            Está seguro que desea eliminar el usuario <b>{nombreUsuario}</b>?{' '}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={handleOnCancel}>
            Cancelar
          </button>
          <button className={styles.loginButton} onClick={handleOnRemove}>
            Eliminar usuario
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EliminarUsuarioModal;
