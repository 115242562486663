import React from 'react';
import { Dimension } from '../../../Dimensiones/Dimension';
import styles from './AdminDimension.module.scss';

export const AdminDimension = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Administración de Dimensiones - Barómetro de Seguridad Integral</div>
      <div className={styles.content}>
        {/* <div className={styles.options}>
          <p>Provincias</p>
          <Select placeholder="Seleccione una provincia" data={provinces} value={selectedProvince} onChange={setSelectedProvince} />
          <div className={styles.options}></div>
          <p>Dimensiones</p>
          <Select placeholder="Seleccione una dimensión" data={dimensions} value={selectedDimension} onChange={setSelectedDimension} />
          <button className={styles.button} onClick={retrieveDimensions}>
            {' '}
            Cargar Datos{' '}
          </button>
        </div> */}
        <div className={styles.indicadores}>
          <div className={styles.listContainer}>
            <Dimension />
          </div>
        </div>
      </div>
    </div>
  );
};
