import React from 'react';
import styles from './Vision.module.scss';
import vision from '../../../../assets/vision.png';

export const Vision = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>Visión</div>
      <div className={styles.content}>
        <img src={vision} alt="vision" className={styles.image} />
        <div className={styles.introduction}>
          Constituirnos, al 2027, en el grupo de análisis y asesoramiento estratégico líder del país, en los ámbitos sociales y de seguridad
          integral, mediante herramientas de big data e inteligencia artificial para los tomadores de decisiones.
        </div>
      </div>
    </div>
  );
};
