import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import Admin from '../pages/administracion/Admin';
import NotFoundPage from '../pages/NotFoundPage';
import Nosotros from '../pages/nosotros';
import NuestroEquipo from '../pages/nuestro-equipo';
import NuestrasSoluciones from '../pages/nuestras-soluciones';
import ElBarometro from '../pages/el-barometro';
import NuestrosObjetivos from '../pages/nuestros-objetivos';
import NuestraMision from '../pages/nuestra-mision';
import NuestraVision from '../pages/nuestra-vision';
import NuestraHistoria from '../pages/nuestra-historia';
import AdminUsuariosPage from '../pages/administracion/AdminUsuarios/AdminUsuariosPage';
import AdminDimensionesPage from '../pages/administracion/AdminDimensiones/AdminDimensionesPage';
import AdminIndicadoresPage from '../pages/administracion/AdminIndicadores/AdminIndicadoresPage';
import CargaDatosPage from '../pages/administracion/CargaDatos/CargaDatosPage';
import MapaPage from '../pages/mapa';
// import AdminIndicadoresPage from '../pages/administracion/AdminVariables/AdminIndicadoresPage';

export const WebNavigator: React.FC<Record<string, never>> = () => {
  let isAdmin: boolean;
  if (sessionStorage.getItem('profile') === '1') {
    isAdmin = true;
  } else isAdmin = false;
  let isLogged;
  if (sessionStorage.getItem('logged') === 'true') {
    isLogged = true;
  } else isLogged = false;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/administracion" element={<Admin />} />
        <Route path="/quienes-somos" element={<Nosotros />} />
        <Route path="/nuestra-historia" element={<NuestraHistoria />} />
        <Route path="/nuestro-equipo" element={<NuestroEquipo />} />
        <Route path="/mision" element={<NuestraMision />} />
        <Route path="//vision" element={<NuestraVision />} />
        <Route path="/objetivos" element={<NuestrosObjetivos />} />
        <Route path="/el-barometro" element={<ElBarometro />} />
        <Route path="/soluciones" element={<NuestrasSoluciones />} />
        <Route path="/administracion/usuarios" element={isAdmin ? <AdminUsuariosPage /> : <NotFoundPage />} />
        <Route path="/administracion/dimensiones" element={isAdmin ? <AdminDimensionesPage /> : <NotFoundPage />} />
        <Route path="/administracion/indicadores" element={isAdmin ? <AdminIndicadoresPage /> : <NotFoundPage />} />
        <Route path="/administracion/carga-datos" element={isAdmin ? <CargaDatosPage /> : <NotFoundPage />} />
        <Route path="/mapa" element={isLogged ? <MapaPage /> : <NotFoundPage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};
