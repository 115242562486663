import React, { useState } from 'react';
import styles from './Header.module.scss';
import facebookIcon from '../../../assets/social-icons/facebook-icon.png';
import twitterIcon from '../../../assets/social-icons/twitter-icon.png';
import tiktokIcon from '../../../assets/social-icons/tiktok-icon.png';
import youtubeIcon from '../../../assets/social-icons/youtube-icon.png';
import mailIcon from '../../../assets/social-icons/mail-icon.png';
import whatsappIcon from '../../../assets/social-icons/whatsapp-icon.png';
import telegramIcon from '../../../assets/social-icons/telegram-icon.png';

import LoginModal from '../../LoginModal/LoginModal';
import logoHeader from '../../../assets/logo-barometec.png';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import lupa from '../../../assets/menu-icons/lupa.png';
import casa from '../../../assets/menu-icons/casa.png';
import nuestroHorizonte from '../../../assets/menu-icons/flecha.png';
import grupoBarometec from '../../../assets/menu-icons/grupo-barometec.png';
import elBarometro from '../../../assets/menu-icons/el-barometro.png';
import calcular from '../../../assets/menu-icons/calcular.png';
import salir from '../../../assets/menu-icons/salir.png';
import LogoutModal from '../../LogoutModal/LogoutModal';
import { HeaderMenuProps } from './Header.types';

export const Header = ({ route, role }: HeaderMenuProps) => {
  const isLogedIn = sessionStorage.getItem('logged') === 'true';
  const isAdmin = sessionStorage.getItem('profile') === '1';
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const handleOnLogout = () => {
    setShowLogoutModal(true);
  };
  const handleOnLogin = () => {
    setShowLoginModal(true);
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <a href="/" style={{ textDecoration: 'none' }}>
              <img src={logoHeader} alt="logo-barometec" height={160} />
            </a>
          </div>
          <div className={styles.rigthItems}>
            <div className={styles.socialIcons}>
              <img
                src={facebookIcon}
                alt="facebook"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://www.facebook.com/people/Grupo-Barometec/100090177837316/');
                }}
              />
              <img
                src={twitterIcon}
                alt="Twitter"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://twitter.com/grupo_barometec');
                }}
              />
              <img
                src={tiktokIcon}
                alt="TikTok"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://www.tiktok.com/@grupobarometec');
                }}
              />
              <img
                src={youtubeIcon}
                alt="Youtube"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://www.youtube.com/@grupobarometec');
                }}
              />
              <a href="mailto:barometec@gmail.com" target="_blank" rel="noreferrer">
                <img src={mailIcon} alt="Correo" className={styles.socialIcon} />
              </a>
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://wa.me/593998520971');
                }}
              />{' '}
              <img
                src={telegramIcon}
                alt="Telegram"
                className={styles.socialIcon}
                onClick={() => {
                  window.open('https://msng.link/o/?Barometec=tg');
                }}
              />
            </div>
            <div className={styles.menuContainer}>
              <div className={styles.menu}>
                <div className={styles.menuItem}>
                  <Menu
                    trigger="hover"
                    openDelay={100}
                    closeDelay={200}
                    styles={{
                      itemLabel: {
                        fontFamily: 'Quicksand',
                        fontSize: '45px'
                      },
                      item: {
                        fontFamily: 'Quicksand',
                        fontSize: '45px'
                      }
                    }}
                    shadow="md"
                    width={200}
                    position="bottom-start"
                  >
                    <Menu.Target>
                      <a href="/" className={styles.target} style={{ textDecoration: 'none' }}>
                        <div className={styles.target}>
                          <img src={casa} className={styles.menuImage} alt="" />
                          <div className={styles.menuLabel}>Inicio</div>
                        </div>
                      </a>
                    </Menu.Target>
                  </Menu>
                </div>
                <div className={styles.menuItem}>
                  <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                    <Menu.Target>
                      <div className={styles.target}>
                        <img src={grupoBarometec} alt="" height={40} />
                        <div className={styles.menuLabel}>Grupo Barometec</div>
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        styles={{
                          itemLabel: {
                            fontFamily: 'Quicksand',
                            fontSize: '45px'
                          },
                          item: {
                            fontFamily: 'Quicksand',
                            fontSize: '45px'
                          }
                        }}
                        onClick={() => navigate('/quienes-somos')}
                      >
                        ¿Quiénes somos?
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => navigate('/nuestra-historia')}>Breve historia de nosotros</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => navigate('/nuestro-equipo')}>Nuestro equipo</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
                <div className={styles.menuItem}>
                  <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                    <Menu.Target>
                      <div className={styles.target}>
                        <img src={nuestroHorizonte} alt="" />
                        <div className={styles.menuLabel}>Nuestro horizonte</div>
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item onClick={() => navigate('/mision')}>Misión</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => navigate('/vision')}>Visión</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => navigate('/objetivos')}>Objetivos</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
                <div className={styles.menuItem}>
                  <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                    <Menu.Target>
                      <div className={styles.target}>
                        <img src={elBarometro} alt="" height={40} />
                        <div className={styles.menuLabel}>Barómetro de la Seguridad Integral</div>
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item onClick={() => navigate('/el-barometro')}>¿De qué se trata?</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => navigate('/soluciones')}>Soluciones</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
                {isLogedIn && (
                  <div className={styles.menuItem}>
                    <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                      <Menu.Target>
                        <div className={styles.target}>
                          <img src={calcular} alt="" height={40} />
                          <div className={styles.menuLabel}>Análisis de Datos</div>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item onClick={() => navigate('/mapa')}>Mapa</Menu.Item>
                        {/* <Menu.Divider />
                        <Menu.Item onClick={() => navigate('/indicadores')}>Indicadores</Menu.Item> */}
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                )}
                {isLogedIn && isAdmin && (
                  <div className={styles.menuItem}>
                    <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                      <Menu.Target>
                        <div className={styles.target}>
                          <img src={lupa} alt="" />
                          <div className={styles.menuLabel}>Administración</div>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item onClick={() => navigate('/administracion/usuarios')}>Administración de Usuarios</Menu.Item>
                        <Menu.Divider />
                        {/* <Menu.Item onClick={() => navigate('/administracion/provincias')}>Administración de Provincias</Menu.Item>
                        <Menu.Divider /> */}
                        <Menu.Item onClick={() => navigate('/administracion/dimensiones')}>Administración de Dimensiones</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item onClick={() => navigate('/administracion/indicadores')}>Administración de Indicadores</Menu.Item>{' '}
                        <Menu.Divider />
                        <Menu.Item onClick={() => navigate('/administracion/carga-datos')}>Carga de Datos</Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                )}

                <div className={styles.menuItem}>
                  <Menu trigger="hover" openDelay={100} closeDelay={200} shadow="md" width={200} position="bottom-start">
                    <Menu.Target>
                      <div className={styles.target} onClick={isLogedIn ? handleOnLogout : handleOnLogin}>
                        <img src={isLogedIn ? salir : lupa} alt="" height={50} />
                        <div className={styles.menuLabel}>{isLogedIn ? 'Salir' : 'Acceso'}</div>
                      </div>
                    </Menu.Target>
                  </Menu>
                </div>

                {/* <div className={styles.menuItem}>
                    <Menu shadow="md" width={200} position="bottom-start">
                    <Menu.Target>
                    <div className={styles.target}>Contactos</div>
                    </Menu.Target>
                    </Menu>
                  </div> */}
                {/* <button className={styles.loginButton} onClick={handleOnClick}>
                    {sessionStorage.getItem('logged') === 'true' ? 'Logout' : 'Login'}
                  </button> */}
              </div>
            </div>
          </div>
          <LoginModal
            showModal={showLoginModal}
            onCloseModal={() => {
              setShowLoginModal(false);
            }}
            onAction={handleOnLogin}
          />
          <LogoutModal
            showModal={showLogoutModal}
            onCloseModal={() => {
              setShowLogoutModal(false);
            }}
            onAction={handleOnLogout}
          />
        </div>
        <div>
          <div className={styles.line}>
            <div style={{ height: '10px', width: '20%', backgroundColor: '#2e76b5' }} />
            <div style={{ height: '10px', width: '20%', backgroundColor: '#99ff66' }} />
            <div style={{ height: '10px', width: '20%', backgroundColor: '#ffff67' }} />
            <div style={{ height: '10px', width: '20%', backgroundColor: '#ff9933' }} />
            <div style={{ height: '10px', width: '20%', backgroundColor: '#ff4f50' }} />
          </div>
        </div>
      </div>
    </>
  );
};
