import React from 'react';
import { CargaDatos } from '../../../components/UI/Admin/CargaDatos/CargaDatos';
import styles from './CargaDatosPage.module.scss';
import { Header } from '../../../components/UI/Header/Header';
import { Footer } from '../../../components/UI/Footer/footer';

const CargaDatosPage: React.FC<Record<string, never>> = () => {
  let content = (
    <>
      <div className={styles.container}>
        <Header />
        <div style={{ minHeight: '600px' }}>
          <CargaDatos />
        </div>
        <Footer />
      </div>
    </>
  );

  return content;
};
export default CargaDatosPage;
