import React from 'react';
import styles from './index.module.scss';

export const UnfocusedArrows = () => {
  return (
    <div>
      <div className={styles.arrowUp} style={{ marginBottom: '0.5rem' }} />
      <div className={styles.arrowDown} />
    </div>
  );
};
