import React, { useEffect, useState } from 'react';
import { AscArrows } from './AscArrows';
import { DescArrows } from './DescArrows';
import { IndicatorNode } from './IndicatorNode';
import { UnfocusedArrows } from './UnfocusedArrows';
import styles from './Indicators.module.scss';
import AgregarIndicadorModal from '../UI/Admin/AdminIndicadores/AgregarIndicadorModal/AgregarIndicadorModal';
import axios from 'axios';

interface IndicatorsData {
  id_indicador: string;
  nombre: string;
  valor_max: string;
  valor: string;
  ponderacion: string;
  id_dimension: string;
}
enum SortBy {
  ID = 'ID',
  NOMBRE = 'NOMBRE',
  DIMENSION = 'DIMENSION',
  VALOR_MAXIMO = 'VALOR_MAXIMO',
  VALOR = 'VALOR',
  PONDERACION = 'PONDERACION'
}

export const IndicatorsAdmin = () => {
  const [sortBy, setSortBy] = useState(SortBy.NOMBRE);
  const [sortAsc, setSortAsc] = useState(true);
  const [indicatorsData, setIndicatorsData] = useState<[]>();
  const [showAddIndicatorsModal, setShowAddIndicatorsModal] = useState(false);
  const [updatedId, setUpdatedId] = useState('');
  let qs = require('qs');
  let dataquery = qs.stringify({
    f: 'consultarIndicador',
    idIndicador: '0'
  });
  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;
  const retrieveindicators = async () => {
    await axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then((response: any) => {
        // console.log(JSON.stringify(response.data));
        setIndicatorsData(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    retrieveindicators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);

  const getOrderByValue = (sortBy: SortBy, ind: IndicatorsData) => {
    switch (sortBy) {
      case SortBy.ID:
        return Number(ind.id_indicador);
      case SortBy.VALOR:
        return Number(ind.valor);
      case SortBy.PONDERACION:
        return Number(ind.ponderacion);
      case SortBy.VALOR_MAXIMO:
        return Number(ind.valor_max);
      case SortBy.DIMENSION:
        return Number(ind.id_dimension);
      case SortBy.NOMBRE:
      default:
        return ind.nombre;
    }
  };

  const indicatorsHeaders = [
    { value: 'id_indicador', name: 'Id Indicador', sortBy: SortBy.ID, width: '60px' },
    { value: 'nombre', name: 'Nombre', sortBy: SortBy.NOMBRE, width: '350px' },
    { value: 'valor_max', name: 'Valor Máximo', sortBy: SortBy.VALOR_MAXIMO, width: '40px' },
    { value: 'valor', name: 'Valor por Defecto', sortBy: SortBy.VALOR, width: '60px' },
    { value: 'ponderacion', name: 'Ponderación', sortBy: SortBy.PONDERACION, width: '60px' },
    { value: 'id_dimension', name: 'Dimensión', sortBy: SortBy.DIMENSION, width: '200px' }
  ];
  // const SortArray = (x: IndicatorsData, y: IndicatorsData) => {
  //   if (sortAsc) {
  //     return getOrderByValue(sortBy, x) < getOrderByValue(sortBy, y);
  //   } else {
  //     return getOrderByValue(sortBy, y) > getOrderByValue(sortBy, x);
  //   }
  // };

  const sortData = () => {
    if (!indicatorsData) {
      return [];
    }
    const dataToSort: IndicatorsData[] = !indicatorsData ? [] : [...indicatorsData];
    if (sortBy === SortBy.ID) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.NOMBRE) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.VALOR) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.PONDERACION) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.DIMENSION) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.VALOR_MAXIMO) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    }
    // else return dataToSort?.sort(SortArray);
  };
  let sortedData = sortData();

  return (
    <>
      {/* {loading ? ( */}
      {/* <div>Cargando variables...</div> */}
      {/* ) : ( */}
      <AgregarIndicadorModal showModal={showAddIndicatorsModal} onCloseModal={() => setShowAddIndicatorsModal(false)} />

      <div>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => setShowAddIndicatorsModal(true)}>
            Agregar Indicador
          </button>
        </div>
        <div className={styles.headerContainer}>
          {indicatorsHeaders.map((header, ix) => {
            return (
              <div
                key={ix}
                className={styles.header}
                onClick={() => {
                  setSortAsc((previous) => !previous);
                  setSortBy(header.sortBy);
                }}
                style={{ width: header.width }}
              >
                {sortBy !== header.sortBy ? <UnfocusedArrows /> : sortAsc ? <AscArrows /> : <DescArrows />}
                <div>{header.name}</div>
              </div>
            );
          })}
          <div style={{ fontWeight: 700, height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Acciones</div>
        </div>
        <div className={styles.alternatingColor}>
          {sortedData!.map((item: IndicatorsData) => {
            return <IndicatorNode key={item.id_indicador} data={item} onUpdatedId={setUpdatedId} />;
          })}
        </div>
      </div>
      {/* )} */}
    </>
  );
};
