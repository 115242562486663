import styles from './UserNode.module.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { UserEdit, UserRemove } from 'iconsax-react';
import { Select } from '@mantine/core';
import EliminarUsuarioModal from '../../UI/Admin/AdminUsuarios/EliminarUsuarioModal/EliminarUsuarioModal';

interface UsuariosData {
  id_usuario: string;
  nombre: string;
  correo: string;
  nombre_usuario: string;
  perfil: string; //ponderacion
  contrasenia: string;
}
interface Props {
  data: UsuariosData | undefined;
  onUpdatedId: (id: string) => void;
}
const perfiles = [
  { value: '1', label: 'Administrador' },
  { value: '2', label: 'Usuario Barómetro' }
];
export const UserNode = ({ data, onUpdatedId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputNombre, setInputNombre] = useState('');
  const [inputCorreo, setInputCorreo] = useState('');
  const [inputNombreUsuario, setInputNombreUsuario] = useState('');
  const [inputContrasena, setInputContrasena] = useState('');
  const [selectedPerfil, setSelectedPerfil] = useState('');
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const handleOnEdit = () => {
    setIsEditing(true);
  };

  const handleOnSave = async () => {
    onUpdatedId('0');
    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'actualizarUsuario',
      idUsuario: data!.id_usuario,
      nombre: inputNombre,
      correo: inputCorreo,
      nombreUsuario: inputNombreUsuario,
      contrasenia: inputContrasena,
      perfil: selectedPerfil
    });
    const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

    await axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then(function (response) {
        console.log(response.data[0]);
        onUpdatedId(data!.id_usuario);

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsEditing(false);
  };

  useEffect(() => {
    setInputNombre(data!.nombre);
  }, [data, data?.nombre]);
  useEffect(() => {
    setInputCorreo(data!.correo);
  }, [data, data?.correo]);
  useEffect(() => {
    setInputNombreUsuario(data!.nombre_usuario);
  }, [data, data?.nombre_usuario]);
  useEffect(() => {
    setInputContrasena(data!.contrasenia);
  }, [data, data?.contrasenia]);
  useEffect(() => {
    setSelectedPerfil(data!.perfil);
  }, [data, data?.perfil]);

  return (
    <>
      {isEditing ? (
        <div className={styles.content}>
          <div className={styles.inputContainer}>{data?.id_usuario}</div>
          <div className={styles.inputContainer}>
            <input className={styles.editInput} value={inputNombre} onChange={(e) => setInputNombre(e.target.value)} />
          </div>
          <div className={styles.inputContainer}>
            <input className={styles.editInput} value={inputCorreo} onChange={(e) => setInputCorreo(e.target.value)} />
          </div>
          <div className={styles.inputContainer}>
            <input className={styles.editInput} value={inputNombreUsuario} onChange={(e) => setInputNombreUsuario(e.target.value)} />
          </div>
          <div className={styles.inputContainer}>
            <Select
              placeholder="Seleccione una dimensión"
              data={perfiles}
              value={selectedPerfil}
              onChange={(value) => setSelectedPerfil(value ? value : '1')}
              className={styles.input}
              radius={12}
            />
          </div>
          <div className={styles.inputContainer}>
            <input className={styles.editInput} value={inputContrasena} onChange={(e) => setInputContrasena(e.target.value)} />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={classNames(styles.button, { [styles.saveButton]: isEditing })}
              onClick={isEditing ? handleOnSave : handleOnEdit}
            >
              Guardar
            </button>
          </div>
        </div>
      ) : (
        <div>
          <EliminarUsuarioModal
            idUsuario={data!.id_usuario}
            nombreUsuario={data!.nombre_usuario}
            showModal={showDeleteUserModal}
            onCloseModal={() => {
              setShowDeleteUserModal(false);
            }}
          />
          <div className={styles.content}>
            <div className={styles.label}>{data?.id_usuario}</div>
            <div className={styles.label}>{data?.nombre}</div>
            <div className={styles.label}>{data?.correo}</div>
            <div className={styles.label}>{data?.nombre_usuario}</div>
            <div className={styles.label}> {perfiles.find((item) => item.value === data?.perfil)?.label}</div>
            <div className={styles.label}>{data?.contrasenia}</div>
            {/* {isEditing ? (
            <div className={styles.inputContainer}>
              <input className={styles.input} value={inputValor} onChange={(e) => setInputValor(e.target.value)} />
            </div>
          ) : (
            <div className={styles.valor}>{data?.valor}</div>
          )} */}

            <div className={styles.buttonContainer}>
              <UserEdit className={styles.icon} id="Editar" onClick={isEditing ? handleOnSave : handleOnEdit} />
              <UserRemove className={styles.icon} onClick={() => setShowDeleteUserModal(true)} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
