import React from 'react';
import { IndicatorsAdmin } from '../../../Indicators_Admin/Indicators';
import styles from './AdminIndicadores.module.scss';

export const AdminIndicadores = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Administración de Indicadores - Barómetro de seguridad</div>
      <div className={styles.content}>
        <div className={styles.indicadores}>
          <IndicatorsAdmin />
        </div>
      </div>
    </div>
  );
};
