import React, { useEffect, useState } from 'react';
import { AscArrows } from './AscArrows';
import { DescArrows } from './DescArrows';
import { UserNode } from './UserNode';
import { UnfocusedArrows } from './UnfocusedArrows';
import styles from './Usuarios.module.scss';
import AgregarUsuarioModal from '../UI/Admin/AdminUsuarios/AgregarUsuarioModal/AgregarUsuarioModal';
import axios from 'axios';

interface UsuariosData {
  id_usuario: string;
  nombre: string;
  correo: string;
  nombre_usuario: string;
  perfil: string; //ponderacion
  contrasenia: string;
}

enum SortBy {
  ID = 'ID',
  NOMBRE = 'NOMBRE',
  CORREO = 'CORREO',
  NOMBRE_USUARIO = 'NOMBRE_USUARIO',
  PERFIL = 'PERFIL',
  CONTRASENA = 'CONTRASENA'
}

export const Usuarios = () => {
  const [sortBy, setSortBy] = useState(SortBy.NOMBRE);
  const [sortAsc, setSortAsc] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [usersData, setUsersData] = useState<[]>();
  const [updatedId, setUpdatedId] = useState('');

  const getOrderByValue = (sortBy: SortBy, us: UsuariosData) => {
    switch (sortBy) {
      case SortBy.ID:
        return us.id_usuario;
      case SortBy.NOMBRE_USUARIO:
        return us.nombre_usuario;
      case SortBy.PERFIL:
        return us.perfil;
      case SortBy.CORREO:
        return us.correo;
      case SortBy.NOMBRE:
      default:
        return us.nombre;
    }
  };
  const retrieveUsers = async () => {
    let qs = require('qs');
    let dataquery = qs.stringify({
      f: 'consultarUsuario',
      idUsuario: '0'
    });
    const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;
    await axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {})
      .then((response: any) => {
        setUsersData(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);
  const indicatorsHeaders = [
    { value: 'id_usuario', name: 'Id Usuario', sortBy: SortBy.ID },
    { value: 'nombre', name: 'Nombre', sortBy: SortBy.NOMBRE },
    { value: 'correo', name: 'Correo Electrónico', sortBy: SortBy.CORREO },
    { value: 'nombre_usuario', name: 'Nombre de Usuario', sortBy: SortBy.NOMBRE_USUARIO },
    { value: 'perfil', name: 'Perfil', sortBy: SortBy.PERFIL },
    { value: 'contrasena', name: 'Contraseña', sortBy: SortBy.CONTRASENA }
    // { value: 'acciones', name: 'Acciones', sortBy: SortBy.NULL }
  ];
  // const SortArray = (x: UsuariosData, y: UsuariosData) => {
  //   if (sortAsc) {
  //     return getOrderByValue(sortBy, x) < getOrderByValue(sortBy, y);
  //   } else {
  //     return getOrderByValue(sortBy, y) > getOrderByValue(sortBy, x);
  //   }
  // };

  const sortData = () => {
    if (!usersData) {
      return [];
    }
    const dataToSort: UsuariosData[] = !usersData ? [] : [...usersData];
    if (sortBy === SortBy.ID) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.NOMBRE) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.CONTRASENA) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.NOMBRE_USUARIO) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.PERFIL) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.CORREO) {
      if (sortAsc) {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: UsuariosData, b: UsuariosData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    }
    // else return dataToSort?.sort(SortArray);
  };
  let sortedData = sortData();

  return (
    <>
      {/* {loading ? ( */}
      {/* <div>Cargando variables...</div> */}
      {/* ) : ( */}
      <AgregarUsuarioModal showModal={showAddUserModal} onCloseModal={() => setShowAddUserModal(false)} />
      <div>
        {' '}
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => setShowAddUserModal(true)}>
            Agregar Usuario
          </button>
        </div>
        <div className={styles.headerContainer}>
          {indicatorsHeaders.map((header, ix) => {
            return (
              <div
                key={ix}
                className={styles.header}
                onClick={() => {
                  setSortAsc((previous) => !previous);
                  setSortBy(header.sortBy);
                }}
              >
                {sortBy !== header.sortBy ? <UnfocusedArrows /> : sortAsc ? <AscArrows /> : <DescArrows />}
                <div>{header.name}</div>
              </div>
            );
          })}
          <div
            style={{
              fontWeight: 700,
              height: '2.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              minWidth: '100px'
            }}
          >
            Acciones
          </div>
        </div>
        <div style={{ height: '1px', backgroundColor: '#9D9D9D', marginBottom: '16px' }} />
        <div className={styles.alternatingColor}>
          {sortedData!.map((item: UsuariosData) => {
            return <UserNode key={item.id_usuario} data={item} onUpdatedId={setUpdatedId} />;
          })}
        </div>
      </div>

      {/* )} */}
    </>
  );
};
