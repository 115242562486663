import React, { useEffect, useState } from 'react';
import { AscArrows } from './AscArrows';
import { DescArrows } from './DescArrows';
import { IndicatorNode } from './IndicatorNode';
import { UnfocusedArrows } from './UnfocusedArrows';
import styles from './Indicators.module.scss';

interface IndicatorsData {
  id_barometro: string;
  id_indicador: string;
  nombre: string;
  id_dimension: string;
  valor_max: string;
  valor: string;
  ponderacion: string;
}
interface Props {
  data: IndicatorsData[] | undefined;
  selectedProvince: string | null;
  selectedDimension: string | null;
  onUpdatedId: (id: string) => void;
}
enum SortBy {
  ID = 'ID',
  NOMBRE = 'NOMBRE',
  VALOR_MAXIMO = 'VALOR_MAXIMO',
  VALOR = 'VALOR',
  PONDERACION = 'PONDERACION'
}

export const Indicators = ({ data, selectedDimension, selectedProvince, onUpdatedId }: Props) => {
  const [sortBy, setSortBy] = useState(SortBy.VALOR_MAXIMO);
  const [sortAsc, setSortAsc] = useState(true);
  const [updatedId, setUpdatedId] = useState('');

  const getOrderByValue = (sortBy: SortBy, ind: IndicatorsData) => {
    switch (sortBy) {
      case SortBy.ID:
        return Number(ind.id_barometro);
      case SortBy.VALOR:
        return Number(ind.valor);
      case SortBy.PONDERACION:
        return Number(ind.ponderacion);
      case SortBy.VALOR_MAXIMO:
        return Number(ind.valor_max);
      case SortBy.NOMBRE:
      default:
        return ind.nombre;
    }
  };
  useEffect(() => {
    onUpdatedId(updatedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);
  const indicatorsHeaders = [
    { value: 'id_barometro', name: 'Id', sortBy: SortBy.ID, width: '40px' },
    { value: 'nombre', name: 'Nombre', sortBy: SortBy.NOMBRE, width: '200px' },
    { value: 'valor_max', name: 'Valor Máximo', sortBy: SortBy.VALOR_MAXIMO, width: '90px' },
    { value: 'ponderacion', name: 'Ponderación', sortBy: SortBy.PONDERACION, width: '90px' },
    { value: 'valor', name: 'Valor', sortBy: SortBy.VALOR, width: '90px' }
  ];
  // const SortArray = (x: IndicatorsData, y: IndicatorsData) => {
  //   if (sortAsc) {
  //     return getOrderByValue(sortBy, x) < getOrderByValue(sortBy, y);
  //   } else {
  //     return getOrderByValue(sortBy, y) > getOrderByValue(sortBy, x);
  //   }
  // };

  const sortData = () => {
    if (!data) {
      return [];
    }
    const dataToSort: IndicatorsData[] = !data ? [] : [...data];
    if (sortBy === SortBy.ID) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.NOMBRE) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
      // } else if (sortBy === SortBy.DIMENSION) {
      //   if (sortAsc) {
      //     return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
      //       getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
      //     );
      //   } else {
      //     return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
      //       getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
      //     );
      //   }
    } else if (sortBy === SortBy.VALOR) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.PONDERACION) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.VALOR_MAXIMO) {
      if (sortAsc) {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: IndicatorsData, b: IndicatorsData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    }
    // else return dataToSort?.sort(SortArray);
  };
  let sortedData = sortData();

  return (
    <>
      {/* {loading ? ( */}
      {/* <div>Cargando variables...</div> */}
      {/* ) : ( */}
      <div>
        <div className={styles.headerContainer}>
          {indicatorsHeaders.map((header, ix) => {
            return (
              <div
                key={ix}
                className={styles.header}
                onClick={() => {
                  setSortAsc((previous) => !previous);
                  setSortBy(header.sortBy);
                }}
                style={{ width: header.width }}
              >
                {sortBy !== header.sortBy ? <UnfocusedArrows /> : sortAsc ? <AscArrows /> : <DescArrows />}
                <div>{header.name}</div>
              </div>
            );
          })}
          <div
            style={{ fontSize: '12px', fontWeight: 700, height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            Acciones
          </div>
        </div>
        <div className={styles.alternatingColor}>
          {sortedData!.map((item: IndicatorsData) => {
            return <IndicatorNode key={item.id_barometro} data={item} onUpdatedId={setUpdatedId} />;
          })}
        </div>
      </div>
      {/* )} */}
    </>
  );
};
