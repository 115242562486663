import React, { useEffect, useState } from 'react';
import { AscArrows } from './AscArrows';
import { DescArrows } from './DescArrows';
import { DimensionNode } from './DimensionNode';
import { UnfocusedArrows } from './UnfocusedArrows';
import styles from './Dimension.module.scss';
import axios from 'axios';

interface DimensionData {
  id_dimension: string;
  nombre: string;
}
enum SortBy {
  ID = 'ID',
  NOMBRE = 'NOMBRE'
}

export const Dimension = () => {
  const [sortBy, setSortBy] = useState(SortBy.ID);
  const [sortAsc, setSortAsc] = useState(true);
  const [dimensionData, setDimensionsData] = useState<[]>();
  const [updatedId, setUpdatedId] = useState('');
  let qs = require('qs');
  let dataquery = qs.stringify({
    f: 'consultarDimension',
    idDimension: '0'
  });
  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  const retrieveDimensions = async () => {
    await axios
      .post(ENDPOINT_SUBMIT || '', dataquery, {
        // headers: headers
      })
      .then((response: any) => {
        setDimensionsData(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    retrieveDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);

  const getOrderByValue = (sortBy: SortBy, dim: DimensionData) => {
    switch (sortBy) {
      case SortBy.ID:
        return dim.id_dimension;
      case SortBy.NOMBRE:
      default:
        return dim.nombre;
    }
  };

  const indicatorsHeaders = [
    { value: 'id_dimension', name: 'Id Dimensión', sortBy: SortBy.ID },
    { value: 'nombre', name: 'Nombre', sortBy: SortBy.NOMBRE }
  ];
  // const SortArray = (x: DimensionData, y: DimensionData) => {
  //   if (sortAsc) {
  //     return getOrderByValue(sortBy, x) < getOrderByValue(sortBy, y);
  //   } else {
  //     return getOrderByValue(sortBy, y) > getOrderByValue(sortBy, x);
  //   }
  // };

  const sortData = () => {
    if (!dimensionData) {
      return [];
    }
    const dataToSort: DimensionData[] = !dimensionData ? [] : [...dimensionData];
    if (sortBy === SortBy.ID) {
      if (sortAsc) {
        return dataToSort?.sort((a: DimensionData, b: DimensionData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: DimensionData, b: DimensionData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    } else if (sortBy === SortBy.NOMBRE) {
      if (sortAsc) {
        return dataToSort?.sort((a: DimensionData, b: DimensionData) =>
          getOrderByValue(sortBy, a) < getOrderByValue(sortBy, b) ? -1 : getOrderByValue(sortBy, a) > getOrderByValue(sortBy, b) ? 1 : 0
        );
      } else {
        return dataToSort?.sort((a: DimensionData, b: DimensionData) =>
          getOrderByValue(sortBy, b) < getOrderByValue(sortBy, a) ? -1 : getOrderByValue(sortBy, b) > getOrderByValue(sortBy, a) ? 1 : 0
        );
      }
    }
    // else return dataToSort?.sort(SortArray);
  };
  let sortedData = sortData();

  return (
    <>
      {/* {loading ? ( */}
      {/* <div>Cargando variables...</div> */}
      {/* ) : ( */}
      <div>
        <div className={styles.headerContainer}>
          {indicatorsHeaders.map((header, ix) => {
            return (
              <div
                key={ix}
                className={styles.header}
                onClick={() => {
                  setSortAsc((previous) => !previous);
                  setSortBy(header.sortBy);
                }}
              >
                {sortBy !== header.sortBy ? <UnfocusedArrows /> : sortAsc ? <AscArrows /> : <DescArrows />}
                <div>{header.name}</div>
              </div>
            );
          })}
          <div
            style={{
              fontWeight: 700,
              height: '2.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              minWidth: '100px',
              marginLeft: '130px'
            }}
          >
            Acciones
          </div>
        </div>
        <div style={{ height: '1px', backgroundColor: '#9D9D9D', marginBottom: '16px' }} />
        <div className={styles.alternatingColor}>
          {sortedData!.map((item: DimensionData) => {
            return <DimensionNode key={item.id_dimension} data={item} onUpdatedId={setUpdatedId} />;
          })}
        </div>
      </div>

      {/* )} */}
    </>
  );
};
