import React from 'react';
import { Header } from '../../components/UI/Header/Header';
import { Footer } from '../../components/UI/Footer/footer';
import styles from './index.module.scss';
import { QuienesSomos } from '../../components/UI/Home/QuienesSomos/QuienesSomos';

const Nosotros: React.FC<Record<string, never>> = () => {
  let content = (
    <>
      <div className={styles.container}>
        <Header />
        <div style={{ minHeight: '600px' }}>
          <QuienesSomos />
        </div>
        <Footer />
      </div>
    </>
  );
  return content;
};
export default Nosotros;
