import React from 'react';
import styles from './Equipo.module.scss';
// import equipo from '../../../../assets/equipo.png';

export const Equipo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Barómetro de Seguridad Integral</div>
      <div className={styles.subtitle}>Nuestro Equipo</div>
      <div className={styles.content}>
        {/* <img src={equipo} alt="equipo" className={styles.image} /> */}
        <div className={styles.introduction}>
          Soy Cristian Ordóñez. Magister en Relaciones Internacionales y magister en Cambio Climático y Desarrollo. Docente universitario e
          investigador. Soy Director General del Grupo Barometec y creador de algunas de las soluciones que ofertamos.
        </div>
      </div>
    </div>
  );
};
