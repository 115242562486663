import React from 'react';
import { Input, Modal } from '@mantine/core';
import { useState } from 'react';
import axios from 'axios';
import { ILoginModal } from './LoginModal.interface';
import styles from './LoginModal.module.scss';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-barometec.png';

const LoginModal = ({ showModal, onCloseModal, onAction }: ILoginModal) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [resp, setresp] = useState<[]>();
  const [hasError, setHasError] = useState(false);
  let qs = require('qs');
  let data = qs.stringify({
    f: 'consultarUsuario',
    idUsuario: '0'
  });

  const ENDPOINT_SUBMIT = process.env.REACT_APP_API_URL;

  const handleOnLogin = () => {
    axios
      .post(ENDPOINT_SUBMIT || '', data, {
        // headers: headers
      })
      .then((response: any) => {
        setresp(response.data[0]);
        console.log('response data ' + JSON.stringify(response.data));
        const found = response.data[0].find((item: any) => item.nombre_usuario === user && item.contrasenia === password);
        if (found) {
          sessionStorage.setItem('logged', 'true');
          sessionStorage.setItem('profile', '1');
          navigate('/');
          onCloseModal();
          window.location.reload();
        } else {
          setHasError(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    console.log('respuesta ' + resp);
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') handleOnLogin();
  };
  return (
    <Modal
      centered
      size={496}
      opened={showModal}
      onClose={onCloseModal}
      title="Ingreso al sistema de administracion"
      styles={{
        modal: { borderRadius: 16 },
        title: {
          paddingLeft: '30px',
          width: '100%',
          textAlign: 'center',
          fontFamily: 'Quicksand',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          marginTop: '20px',
          marginBottom: '13px'
        }
      }}
    >
      <div className={styles.modalContainer}>
        <img src={logo} alt="logo" height={100} />
        <div className={styles.loginContainer}>
          <div className={styles.username}>Nombre de Usuario</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Usuario"
              type="text"
              radius={12}
              value={user}
              onChange={(e) => {
                setHasError(false);
                setUser(e.target.value);
              }}
              className={styles.input}
            />
          </div>
          <div className={styles.username}>Contraseña</div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Contraseña"
              type="password"
              radius={12}
              value={password}
              onChange={(e) => {
                setHasError(false);
                setPassword(e.target.value);
              }}
              className={styles.input}
              style={{ height: '40px', border: '0px' }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        {hasError && <div style={{ color: 'red' }}> Error en nombre de usuario o contraseña</div>}
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={onCloseModal}>
            Cancelar
          </button>
          <button className={styles.loginButton} onClick={handleOnLogin}>
            Ingresar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
